<template>
  <auth-layout>
    <form @submit.prevent="submit" method="post">
      <div class="form-group">
        <label for="inputEmail">Email</label>
        <input
          id="inputEmail"
          type="text"
          :class="error ? 'form-control is-invalid' : 'form-control'"
          autocomplete="off"
          v-model="form.username"
          placeholder="Username"
          :disabled="loading"
          required
        />
      </div>
      <div class="form-group">
        <label class="col-form-label" for="inputPassword">Password</label>
        <input
          id="inputPassword"
          type="password"
          :class="error ? 'form-control is-invalid' : 'form-control'"
          autocomplete="off"
          v-model="form.password"
          placeholder="Password"
          :disabled="loading"
          required
        />
        <span v-if="error" class="text-red font-weight-bold"
          ><i class="fas fa-info-circle mr-1 mt-2"></i>{{ error }}</span
        >
      </div>
      <div class="row">
        <!-- <div class="col-7">
                  <div class="icheck-primary">
                    <input
                      type="checkbox"
                      v-model="form.remember"
                      id="remember"
                      :disabled="loading"
                    />
                    <label for="remember">
                      Remember Me
                    </label>
                  </div>
                </div> -->
        <!-- /.col -->
        <div class="col-12">
          <!-- <div class="social-auth-links mb-3">
            <router-link :to="{ name: 'forgot_password' }"
              >Lupa Password
            </router-link>
          </div> -->
          <button
            type="submit"
            class="btn vm-btn-submit btn-block btn-flat"
            :disabled="loading"
          >
            <i class="fas fa-spinner fa-spin" v-if="loading"></i>
            {{ loading ? "..." : "Masuk" }}
          </button>
        </div>
        <!-- /.col -->
      </div>
    </form>
  </auth-layout>
</template>

<script>
const { console } = window;
import AuthLayout from "@components/layouts/Auth.vue";
export default {
  components: {
    AuthLayout,
  },
  data: () => ({
    title_submit: "Sign In",
    form: {},
    error: undefined,
    loading: false,
  }),
  mounted() {
    if (Api.getToken()) {
      this.$router.replace({ name: "Dashboard" });
    }
    $("body").attr("class", "hold-transition vm-login-page text-sm");
    $("body,html").removeAttr("style");
    new adminlte.Layout("body", {});
  },
  methods: {
    submit() {
      this.title_submit = "Processing...";
      this.loading = true;
      Api.login(this.form.username, this.form.password)
        .then((res) => {
          this.loading = false;
          Api.setToken(res, this.form.remember);
          this.$router.replace({ name: "Dashboard" });
        })
        .catch((err) => {
          this.loading = false;
          this.title_submit = "Sign In";
          if (err.response.data.error_description == "Bad credentials") {
            this.error = "Incorrect username or password";
          }
          console.log(this.error);
        });
    },
  },
};
</script>
